import { useResultStore } from '../store/result';

export default {
  created() {
    this.resultStore = useResultStore();
  },
  computed: {
    results() {
      return this.resultStore.getResults;
    },
    resultsLoaded() {
      return this.resultStore.loaded;
    },
  },
  methods: {
    getQueryFromParameters(parameters) {
      const r = {};

      if (parameters.search) {
        r.search = parameters.search;
      }
      if (parameters.empty_search !== undefined) {
        r.szukaj_wszedzie = parameters.empty_search;
      }
      if (parameters.facility_type) {
        r.typ_placowki = parameters.facility_type;
      }
      if (parameters.city) {
        r.miasto = parameters.city;
      }
      if (parameters.radius) {
        r.odleglosc = parameters.radius;
      }
      if (parameters.product_slug) {
        r.produkt = parameters.product_slug;
      }
      if (parameters.category_slug) {
        r.kategoria = parameters.category_slug;
      }

      return r;
    },
    getParametersFromQuery(query) {
      const r = {};

      if (query.search) {
        r.search = query.search;
      }
      if (query.szukaj_wszedzie) {
        r.empty_search = query.szukaj_wszedzie;
      }
      if (query.typ_placowki) {
        r.facility_type = query.typ_placowki;
      }
      if (query.miasto) {
        r.city = query.miasto;
      }
      if (query.odleglosc) {
        r.radius = query.odleglosc;
      }
      if (query.produkt) {
        r.product_slug = query.produkt;
      }
      if (query.kategoria) {
        r.category_slug = query.kategoria;
      }

      return r;
    },
    async loadResultsFromQuery(type) {
      this.resultStore.resetResults();
      this.resultStore.setSearchParameters(this.getParametersFromQuery(this.$route.query));
      this.resultStore.setEndpoint(type);
      await this.resultStore.loadResults();
    },
  },
};
