import { radiusDefault } from '../store/search-default-data';

export default {
  methods: {
    async getLocalisationSearchResultsFromAPI() {
      return this.loadResultsFromQuery('localization-search');
    },
    async getAllFacilitiesSearchResultsFromAPI(facility_type) {
      return this.loadResultsFromQuery('facilities');
    },
    async getCategorySearchResultsFromAPI() {
      return this.loadResultsFromQuery('search-by-category');
    },
    async getProductSearchResultsFromAPI() {
      return this.loadResultsFromQuery('search-by-product');
    },
    loadArticles($state, type) {
      try {
        if (type === 'category' && this.page < this.categoryPages.lastPage && this.categoryPages.lastPage > 1) {
          this.page++;
          this.loadingMore = true;
          this.blogStore
            .getMoreArticlesByCategorySlugByPage({
              slug: this.$route.params.category,
              page: this.page,
            })
            .then(() => {
              $state.loaded();
              this.loadingMore = false;
            });
        } else if (type === 'index' && this.page < this.indexPages.lastPage && this.indexPages.lastPage > 1) {
          this.page++;
          this.loadingMore = true;
          this.blogStore
            .getMoreArticlesByPage({
              page: this.page,
            })
            .then(() => {
              $state.loaded();
              this.loadingMore = false;
            });
        } else if (type === 'tag' && this.page < this.tagPages.lastPage && this.tagPages.lastPage > 1) {
          this.page++;
          this.loadingMore = true;
          this.blogStore
            .getMoreArticlesByTagIdByPage({
              tag_id: this.$route.params.tagId,
              page: this.page,
            })
            .then(() => {
              $state.loaded();
              this.loadingMore = false;
            });
        } else {
          $state.complete();
        }
      } catch (error) {
        $state.error();
      }
    },

    getImageByPaymentName(name) {
      let imageName = '';
      switch (name) {
        case 'Przelew':
          imageName = 'payment-bank-transfer';
          break;
        case 'Karta płatnicza':
          imageName = 'payment-credit-card';
          break;
        case 'Gotówka':
          imageName = 'payment-cash';
          break;
        case 'Blik':
          imageName = 'payment-blik';
          break;
      }
      return imageName;
    },

    getImageUrl(type, name) {
      return `/assets/img/${type}/${name}.png`;
    },
    getImageUrlSvg(type, name) {
      return `/assets/img/${type}/${name}.svg`;
    },
    getImageUrlWebp(type, name) {
      return `/assets/img/${type}/${name}.webp`;
    },
    compareProperty(a, b, property) {
      const property1 = a[property].toUpperCase();
      const property2 = b[property].toUpperCase();

      let comparison = 0;

      if (property1 > property2) {
        comparison = 1;
      } else if (property1 < property2) {
        comparison = -1;
      }

      return comparison;
    },
    unSlugify(slug) {
      const slug2 = slug.replaceAll(('-', ' '));
      const arr = slug2.split(' ');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(' ');
    },
    slugify(title) {
      let slug = '';
      // Change to lower case
      const titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');

      return slug;
    },

    resetSearch() {
      console.log('before reset?');
      this.search = null;
      this.city = null;
      this.radius = radiusDefault;
      this.isOnline = false;
      this.isTouched = {
        search: false,
        city: false,
        radius: false,
      };
      this.isInvalid = {
        search: false,
        city: false,
        radius: false,
      };
      console.log('after reset?');
    },

    handleRedirectToArticle(data) {
      this.$router.push(`/blog/${data.category.slug}/${data.id}/${data.slug}`);
    },
  },
};
