import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'

export interface CmsImageContent {
  large_png: string
  large_webp: string
  medium_png: string
  medium_webp: string
  original: string
  original_png: string
  original_webp: string
  small_png: string
  small_webp: string
}

export interface ArticleAuthor {
  id: number
  name: string
  surname: string
  description: string
  images: {
    image: string
  }
}

export interface ArticleCategory {
  id: number
  slug: string
  name: string
  position: number
  description: string
  seo_slug: string | null
  seo_title: string | null
  ser_description: string | null
  seo_meta_keywords: string | null
  images: {
    image: CmsImageContent
    icon: { svg: string }
  }
  extras: null
}

export interface ArticleRelatedInsuranceCategory {
  type: string
  name: string
}

export interface Article {
  id: number
  slug: string
  category: ArticleCategory
  author: ArticleAuthor
  title: string
  content: string
  short_content: string
  status: string
  position: number
  tag: string
  publication_date: string
  seo_slug: string | null
  seo_title: string | null
  seo_description: string | null
  seo_meta_keywords: string | null
  related_articles: Article[]
  related_insurance_category: ArticleRelatedInsuranceCategory | null
  images: {
    image: CmsImageContent
  }
  extras: {
    image_alt_text: string
  }
}

export interface Category {
  id: number
  name: string
  title: string
  content: string
  images: {
    svg: string
  }
  extras: {
    image_alt_text: string
  }
  seo_title: string
  seo_description: string
}

export interface BlogState {
  singleArticle: Article | null
  articles: Article[]
  articlesDownloaded: boolean
  homepageArticles: Article[]
  homepageArticlesDownloaded: boolean
  categories: Category[]
  categoryArticles: Article[]
  categoryData: Category | null
  tagArticles: Article[]
  tagData: {}
  categoryPages: {
    currentPage: number
    lastPage: number
  }
  indexPages: {
    currentPage: number
    lastPage: number
  }
  tagPages: {
    currentPage: number
    lastPage: number
  }
}

export const useBlogStore = defineStore('blog', {
  state(): BlogState {
    return {
      singleArticle: null,
      articles: [],
      articlesDownloaded: false,
      homepageArticles: [],
      homepageArticlesDownloaded: false,
      categories: [],
      categoryArticles: [],
      categoryData: null,
      tagArticles: [],
      tagData: {},
      categoryPages: {
        currentPage: 1,
        lastPage: 2,
      },
      indexPages: {
        currentPage: 1,
        lastPage: 2,
      },
      tagPages: {
        currentPage: 1,
        lastPage: 2,
      },
    }
  },
  actions: {
    async getArticlesFromAPI() {
      const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/articles`)

      this.articles = results.data.data
      this.articlesDownloaded = true
    },
    async getHomepageArticlesFromAPI() {
      if (this.homepageArticlesDownloaded) {
        return;
      }
      const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/homepage/articles`)

      this.homepageArticles = results.data.data
      this.homepageArticlesDownloaded = true
    },
    async getMoreArticlesByPage(indexData) {
      const moreArticles = await axios.get(`${import.meta.env.VITE_API_URL}/v1/articles?page=${indexData.page}`)

      moreArticles.data.data.length > 0
        ? this.articles = [...this.articles, ...moreArticles.data.data]
        : null

      this.indexPages.currentPage = moreArticles.data.meta.current_page
      this.indexPages.lastPage = moreArticles.data.meta.last_page
    },
    async getCategoriesFromAPI() {
      const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/blog-categories`)

      this.categories = results.data.data
    },
    async getArticleFromAPI(id: number, slug: string) {
      const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/articles/${id}`)

      this.singleArticle = results.data.data
    },
    async getArticlesByCategorySlugFromAPI(slug: string) {
      try {
        const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/blog-categories/${slug}`)
        this.categoryArticles = results.data.data
        this.categoryData = results.data.category
        return Promise.resolve(results)
      } catch (error) {
        this.categoryArticles = []
        this.categoryData = {}
        return Promise.reject()
      }
    },
    async getMoreArticlesByCategorySlugByPage(slugData) {
      const moreCategoryArticles = await axios.get(`${import.meta.env.VITE_API_URL}/v1/blog-categories/${slugData.slug}?page=${slugData.page}`)

      moreCategoryArticles.data.data.length > 0
        ? this.categoryArticles = [...this.categoryArticles, ...moreCategoryArticles.data.data]
        : null

      this.categoryPages.currentPage = moreCategoryArticles.data.meta.current_page
      this.categoryPages.lastPage = moreCategoryArticles.data.meta.last_page
    },
    async getArticlesByTagIdFromAPI(tag_id) {
      try {
        const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-articles?tag_id=${tag_id}`)
        this.tagArticles = results.data.data
        this.tagData = results.data.tag
        return Promise.resolve(results)
      } catch (error) {
        this.tagArticles = []
        this.tagData = {}
        return Promise.reject(error)
      }
    },
    async getMoreArticlesByTagIdByPage(tagData) {
      const moreTagArticles = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-articles?tag_id=${tagData.tag_id}&page=${tagData.page}`)

      moreTagArticles.data.data.length > 0
        ? this.tagArticles = [...this.tagArticles, ...moreTagArticles.data.data]
        : null

      this.tagData = moreTagArticles.data.tag
      this.tagPages.currentPage = moreTagArticles.data.meta.current_page
      this.tagPages.lastPage = moreTagArticles.data.meta.last_page
    },
  },
  getters: {
    getArticles: (state: BlogState) => state.articles,
    getHomepageArticles: (state: BlogState) => state.homepageArticles && state.homepageArticles,
    getArticlesLimitedByNumber: (state: BlogState) => (number: number) => state.articles && state.articles.slice(0, number),
    getArticlesLimitedThree: (state: BlogState) => state.articles && state.articles.slice(0, 3),
    getArticleBySlug: (state: BlogState) => (slug: string) => state.articles && state.articles.find(v => v.seo_slug === slug),
    getArticlesByCategorySlug: (state: BlogState) => (categorySlug: string) => state.articles && state.articles.filter(v => v.category_slug === categorySlug),
    getSingleArticle: (state: BlogState) => state.singleArticle && state.singleArticle,
    getCategories: (state: BlogState) => state.categories && state.categories,
    getCategoryByName: (state: BlogState) => (name: string) => state.categories && state.categories.find(v => v.name === name),
    getCategoryDataBySlug: (state: BlogState) => (slug: string) => state.categories && state.categories.find(v => v.slug === slug),
    getCategoryArticles: (state: BlogState) => state.categoryArticles && state.categoryArticles,
    getCategoryData: (state: BlogState) => state.categoryData && state.categoryData,
    getCategoryPages: (state: BlogState) => state.categoryPages && state.categoryPages,
    getTagArticles: (state: BlogState) => state.tagArticles && state.tagArticles,
    getTagData: (state: BlogState) => state.tagData && state.tagData,
    getTagPages: (state: BlogState) => state.tagPages && state.tagPages,
    getIndexPages: (state: BlogState) => state.indexPages && state.indexPages,
  },
  persist: false,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBlogStore, import.meta.hot))
}
