import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { sortingDefaultOptions } from './search-default-data';

import type { Facility } from '~/store/facility';
import { facilityImagePlaceholder } from '~/store/facility-default-data';

export interface ResultState {
  results: Facility[];
  searchParameters: SearchDataRequest | object;
  sorting: {
    name: string;
    method: string;
  } | null;
  resultsPages: {
    currentPage: number;
    lastPage: number;
  };
  extendedRadius: boolean;
  loading: boolean;
  loaded: boolean;
  endpoint: string;
}

export interface SearchDataRequest {
  search?: string;
  city?: number;
  radius?: number;
  facility_type?: string;
  page?: number;
  empty_search?: number;
  category_slug?: string;
  product_slug?: string;
}

export const getTodayBusinessHours = (businessHours: any) => {
  const today = new Date().getUTCDay();
  const dayNumber = today === 0 ? '7' : `${today}`;
  return businessHours ? businessHours.find((v: any) => v.day === dayNumber && v.open_time !== '' && v.close_time !== '') : 'Zamknięte';
};

export const useResultStore = defineStore('result', {
  state(): ResultState {
    return {
      results: [],
      searchParameters: {},
      sorting: sortingDefaultOptions[0],
      resultsPages: {
        currentPage: 1,
        lastPage: 11,
      },
      extendedRadius: true,
      loading: false,
      loaded: false,
      endpoint: `${import.meta.env.VITE_API_URL}/v1/search`,
    };
  },
  actions: {
    resetResults() {
      this.loading = false;
      // this.loaded = false;
      this.extendedRadius = false;
      this.results = [];
      this.resultsPages.currentPage = 1;
      this.resultsPages.lastPage = 11;
    },
    resetPage() {
      this.resultsPages.currentPage = 1;
      this.resultsPages.lastPage = 11;
    },
    setSearchParameters(params: object) {
      this.searchParameters = params;
    },
    setEndpoint(type: string) {
      if (type === 'search') {
        this.endpoint = `${import.meta.env.VITE_API_URL}/v1/search`;
      } else if (type === 'search-by-category') {
        this.endpoint = `${import.meta.env.VITE_API_URL}/v1/search-by-category`;
      } else if (type === 'search-by-product') {
        this.endpoint = `${import.meta.env.VITE_API_URL}/v1/search-by-product`;
      } else if (type === 'facilities') {
        this.endpoint = `${import.meta.env.VITE_API_URL}/v1/facilities`;
      } else if (type === 'localization-search') {
        this.endpoint = `${import.meta.env.VITE_API_URL}/v1/localization-search`;
      } else {
        console.error(`Undefined endpoint type: ${type}`);
      }
    },
    loadResults(extended = false) {
      const searchParams = JSON.parse(JSON.stringify(this.$state.searchParameters));
      searchParams.sort = this.sorting ? this.sorting.method : 'distance';
      searchParams.page = JSON.parse(JSON.stringify(this.resultsPages.currentPage));

      if (this.extendedRadius) {
        extended = true;
      }

      if (extended && searchParams.radius) {
        searchParams.radius = parseInt(searchParams.radius) + 50;
      }

      const query = new URLSearchParams(searchParams).toString();

      this.loading = true;
      return axios
        .get(`${this.endpoint}?${query}`)
        .then((response: AxiosResponse) => {
          const data = response.data;

          if (!extended && data.data.length === 0 && this.resultsPages.currentPage === 1) {
            this.loadResults(true);
            return;
          }

          data.data.forEach((v: Facility) => {
            v.rating = parseFloat(v.opinions_avg);
            v.today_business_hours = getTodayBusinessHours(v.business_hours);
            v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            v.imagesArray = Object.values(v.images);
            v.imagesPlaceholderArray = [facilityImagePlaceholder];
          });

          this.extendedRadius = extended;

          if (this.results.length === 0 || this.resultsPages.currentPage === 1) {
            this.results = data.data;
          } else {
            data.data.forEach((v: Facility) => {
              this.results.push(v);
            });
          }

          this.resultsPages.currentPage = data.meta.current_page;
          this.resultsPages.lastPage = data.meta.last_page;
          this.loading = false;
          this.loaded = true;
          return data.data.length;
        });
    },
    loadMore() {
      this.resultsPages.currentPage = this.resultsPages.currentPage + 1;
      return this.loadResults();
    },
  },
  getters: {
    getResults: (state: ResultState) => state.results,
    getResultsPages: (state: ResultState) => state.resultsPages,
    getExtendedRadius: (state: ResultState) => state.extendedRadius,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useResultStore, import.meta.hot));
}
