<template>
  <div v-if="data.imagesPlaceholderArray.length === 1" class="agency-card-small">
    <!-- Agency img -->
    <picture>
      <source :srcset="getAgencyImage(true)" type="image/webp">
      <source :srcset="getAgencyImage(false)" type="image/png">
      <img
        class="agency-card-small__image hover-pointer"
        :src="getAgencyImage(false)"
        :alt="data.name"
        @click="handleFacilityDetails(data.id, data.slug)"
      >
    </picture>
    <!-- Agency 1st content column -->
    <div class="agency-card-small__content">
      <div v-if="data.opinions_avg" class="agency-card-small__content--rating stars">
        <span v-for="r in parseInt(data.opinions_avg)" :key="r" class="grade-star">
          <IconStar />
        </span>
      </div>

      <!-- ADDRESS / TEL DATA -->
      <div class="agency-card-small__content--info">
        <div>
          <h3 class="text-secondary-1 agency-card-small__heading">
            <RouterLink
              :to="{path: `/agencje-ubezpieczeniowe/agencja/${data.id}/${data.slug ?? ''}` }"
              class="link"
            >
              {{ data.name }}
            </RouterLink>
          </h3>
        </div>

        <div v-if="data.type === 'stationary'">
          <p class="text-extra-small text-narrow text-secondary-2">
            ul. {{ data.street }} {{ data.building }} {{ data.apartment ? ('/ ' + data.apartment) : '' }}
          </p>
          <p class="text-extra-small text-narrow text-secondary-2">
            {{ data.zip_code }}
            {{ data.city ? data.city.name : '' }}
          </p>
        </div>

        <div v-if="data.type === 'online'">
          <p class="text-extra-small text-narrow text-secondary-2">
            Agencja online
          </p>
        </div>
      </div>

      <div class="agency-card-small__content--button">
        <button class="button button--secondary button--extra-small" @click="handleFacilityDetails(data.id, data.slug)">
          <span class="text-extra-small text-bold">Zobacz szczegóły</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import global from '../../mixins/global';
import links from '../../mixins/links';

export default {
  name: 'AgencyHomepageCard',
  mixins: [global, links],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  methods: {
    getAgencyImage(webp) {
      return this.data.images && this.data.imagesArray[0]
        ? webp
          ? this.data.imagesArray[0].medium_webp
          : this.data.imagesArray[0].medium_png
        : webp
          ? this.data.imagesPlaceholderArray[0].medium_webp
          : this.data.imagesPlaceholderArray[0].medium_png
    },
  },
}
</script>
