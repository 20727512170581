import axios from 'axios';
import { acceptHMRUpdate, defineStore } from 'pinia';
import {
  citiesDefaultOptions,
  cityDefault,
  radiusDefault,
  radiusDefaultOptions,
  searchDefaultOptions,
  sortingDefaultOptions,
  sortingDefaultOptionsOnline,
} from './search-default-data';
import type { Facility } from '~/store/facility';
import type { Product } from '~/store/products';

export interface SearchSubcategory {
  slug: string;
  type: string;
  name: string;
  description: string;
  images: {
    icon: {
      svg: string;
    };
  };
}

export interface SearchCategory {
  type: string;
  name: string;
  subcategories: SearchSubcategory[];
}

export interface City {
  id: number;
  name: string;
  voivodeship: string;
  district: string;
  community: string;
}

export interface Radius {
  id: number;
  radius: number;
  name: string;
}

export interface Sorting {
  name: string;
  method: string;
}

export interface SearchState {
  results: Facility[] | string;
  resultsPages: {
    currentPage: number;
    lastPage: number;
  };
  searchParameters: object;
  sorting: {
    name: string;
    method: string;
  } | null;
  extendedRadius: boolean;
  search: SearchSubcategory | string | null;
  city: City | null;
  radius: Radius | null;
  isOnline: boolean;
  isInvalid: {
    search: boolean;
    city: boolean;
    radius: boolean;
  };
  isTouched: {
    search: boolean;
    city: boolean;
    radius: boolean;
  };
  categorySearchEmits: {
    emit: boolean;
    value: SearchSubcategory;
  };
  defaultSearchOptions: SearchCategory[];
  defaultSearchOptionsDownloaded: boolean;
  defaultCityOptions: City[];
  defaultCityOptionsDownloaded: boolean;
  defaultCity: City;
  defaultRadiusOptions: Radius[];
  defaultRadius: Radius;
}

export const useSearchStore = defineStore('search', {
  state(): SearchState {
    return {
      search: null,
      city: null,
      radius: radiusDefault,
      isOnline: false,
      isInvalid: {
        search: false,
        city: false,
        radius: false,
      },
      isTouched: {
        search: false,
        city: false,
        radius: false,
      },
      categorySearchEmits: {
        emit: false,
        value: searchDefaultOptions[0].subcategories,
      },
      productSearchEmits: {
        emit: false,
        value: '',
      },
      defaultSearchOptions: searchDefaultOptions,
      defaultSearchOptionsDownloaded: false,
      defaultCityOptions: citiesDefaultOptions,
      defaultCityOptionsDownloaded: false,
      defaultCity: cityDefault,
      defaultRadiusOptions: radiusDefaultOptions,
      defaultRadius: radiusDefault,
    };
  },
  actions: {
    resetSearch() {
      this.search = null;
      this.city = null;
      this.isInvalid = {
        search: false,
        city: false,
        radius: false,
      };
      this.isTouched = {
        search: false,
        city: false,
        radius: false,
      };
    },
    async getSearchPlaceholders() {
      if (this.defaultSearchOptionsDownloaded) {
        return;
      }
      try {
        const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-placeholders`);
        this.defaultSearchOptions = res.data.data;
        this.defaultSearchOptionsDownloaded = true;
      } catch (error) {
        this.defaultSearchOptionsDownloaded = false;
      }
    },
    async getDefaultCities() {
      if (this.defaultCityOptionsDownloaded) {
        return;
      }
      try {
        const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/default-cities`);
        this.defaultCityOptions = res.data.data;
        this.defaultCityOptionsDownloaded = true;
      } catch (error) {
        this.defaultCityOptionsDownloaded = false;
      }
    },
    async setCategoryEmits(emit: boolean, value: SearchSubcategory) {
      this.categorySearchEmits.emit = emit;
      this.categorySearchEmits.value = value;
    },
    async setProductEmits(emit: boolean, value: Product) {
      this.productSearchEmits.emit = emit;
      this.productSearchEmits.value = value;
    },
  },
  getters: {
    getResults: (state: SearchState) => state.results,
    getResultById: (state: SearchState) => (id) => state.results && state.results.find((v) => v.id === Number(id)),
    getExtendedRadius: (state: SearchState) => state.extendedRadius && state.extendedRadius,
    getSearchEmitsCategory: (state: SearchState) => state.categorySearchEmits && state.categorySearchEmits,
    getDefaultCity: (state: SearchState) => state.defaultCity && state.defaultCity,
    getDefaultSearchOptions: (state: SearchState) => state.defaultSearchOptions && state.defaultSearchOptions,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot));
}
