<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-layout">
        <div class="footer-layout__menus">
          <!-- Footer main menu -->
          <div class="footer-layout__main-menu">
            <RouterLink to="/" class="footer-layout__logo-link">
              <img src="@/assets/img/logo.svg" alt="logo" class="footer-layout__logo-img">
            </RouterLink>
            <ul class="footer-layout__main-menu-list">
              <li v-for="menuLink in menuCol1Links" :key="menuLink.id">
                <RouterLink
                  v-if="menuLink.id !== 2"
                  :to="menuLink.to"
                  class="footer__col1--link text-bold text-extra-small"
                >
                  <strong>{{ menuLink.name }}</strong>
                </RouterLink>
                <a
                  v-if="menuLink.id === 2"
                  :href="menuLink.to"
                  class="footer__col1--link text-bold text-extra-small"
                  target="_blank"
                >
                  <strong>{{ menuLink.name }}</strong>
                </a>
              </li>
            </ul>

            <div class="footer__col1--button">
              <a class="button button--secondary button--small" href="/admin/login">
                <span class="text-extra-small text-bold">Panel Agenta</span>
              </a>
            </div>
          </div>
          <!-- /Footer main menu -->

          <!-- Footer seo links - 1 -->
          <div class="footer-layout__menus--column2">
            <span class="text-extra-small footer-layout__menus-title">Ubezpieczenia dla Ciebie</span>

            <ul class="footer-layout__menu-list footer-layout__menu-list--col-1">
              <li v-for="product in products" :key="product.id">
                <RouterLink
                  :to="{path: `/dobry-agent-poleca/${product.slug}`}"
                  class="footer__link text-narrow text-extra-small"
                >
                  {{ product.title }}
                </RouterLink>
              </li>
            </ul>
          </div>
          <!-- /Footer seo links - 1 -->

          <!-- Footer seo links - 2 -->
          <div class="footer-layout__menus--column3">
            <span class="text-extra-small footer-layout__menus-title">Kategorie Ubezpieczeń</span>
            <ul class="footer-layout__menu-list footer-layout__menu-list--col-2">
              <li v-for="category in categories" :key="category.name">
                <span class="footer__link text-narrow text-extra-small hover-pointer" @click="handleCategorySearch(category)">{{ category.name }} </span>
              </li>
            </ul>
          </div>
          <!-- /Footer seo links - 2 -->
        </div>

        <!-- Copyrights -->
        <div class="footer-layout__copyright my-6 container">
          <p class="text-center">
            &copy; Copyright 2021 | ASF Premium | Dobry Agent - Agenci Ubezpieczeniowi z całej Polski
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useProductsStore } from '../../store/products'
import { useInsurancesStore } from '../../store/insurances'
import { useSearchStore } from '../../store/search'

const menuCol1LinksDef = [
  { id: 1, name: 'Kontakt', to: '/kontakt' },
  { id: 2, name: 'Dołącz do nas', to: 'https://wspolpraca.dobryagent.pl/' },
  { id: 3, name: 'Polityka Prywatności', to: '/polityka-prywatnosci' },
  { id: 4, name: 'RODO', to: '/rodo' },
  { id: 5, name: 'Regulamin', to: '/regulamin' },
]

export default {
  name: 'CommonFooter',
  setup() {
    const insurancesStore = useInsurancesStore()
    const productsStore = useProductsStore()
    const searchStore = useSearchStore()

    return {
      insurancesStore,
      productsStore,
      searchStore,
    }
  },
  data() {
    return {
      menuCol1Links: menuCol1LinksDef,
    }
  },
  computed: {
    categories() {
      return this.insurancesStore.getInsurancesLimitedByNumber(9)
    },
    products() {
      return this.productsStore.getProductsLimitedByNumber(9)
    },
  },
  beforeCreate() {
    this.productsStore.getRecommendedProductsFromAPI();
  },
  methods: {
    handleCategorySearch(category) {
      this.searchStore.setCategoryEmits(true, category)
    },
  },
}
</script>
